import { graphql } from "gatsby";
import React from "react";
import Contact from "../components/contact";
import { SEO } from "../components/seo";

const ContactPage = ({ data }) => {
  const contact = data.prismicContact.data;
  return (
    <div>
      <SEO
        title="Contact - Morch"
        description="MØRCH IS A FASHION SALES AGENCY FOUNDED BY LOTTE MØRCH IN 1996 AND IS ONE OF THE LEADING FASHION AGENCIES IN SCANDINAVIA."
      />

      <Contact data={contact} />
    </div>
  );
};

export default ContactPage;

export const query = graphql`
  {
    prismicContact {
      data {
        mail
        telefon
        showroom
      }
    }
  }
`;
